import BizmixTopNavigation from '../../Components/NewBizmix/BizmixTopNavigation';

export default function BizmixLayout({ children }) {
  const injectGA = () => {
    if (typeof window == 'undefined') {
      return;
    }
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'UA-121558694-1');
  };

  return (
    <div>
      <div>
        {/* Global site tag (gtag.js) - Google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-121558694-1" />
        <script>{injectGA()}</script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-121558694-1"
        ></script>
      </div>

      <div
        className="bg-cover bg-fixed bg-no-repeat min-h-screen"
        style={{
          backgroundImage: 'url(/images/business-mixer/main-bg-old.png)'
        }}
      >
        {/* <div
        className="bg-cover bg-fixed bg-no-repeat h-full"
        style={{ backgroundImage: 'url(https://nextdaysite.com/demo/bizmixbanner/main-bg.png)' }} Christmas banner
      > */}
        <section className="h-full min-h-screen w-full">
          <BizmixTopNavigation />
          <main className="">{children}</main>
        </section>
      </div>
    </div>
  );
}

import { Link } from 'react-router-dom';
import { useState } from 'react'; // Import useState hook

export default function BizmixTopNavigation() {
  const links = [
    {
      title: 'Home',
      slug: '/bizmix#hero',
      type: 'link'
    },
    {
      title: 'About',
      slug: '/bizmix#about',
      type: 'link'
    },
    {
      title: 'Gallery',
      slug: '/bizmix/gallery',
      type: 'link'
    },
    {
      title: 'Register Now',
      slug: 'https://connectnigeria.com/bizmix/signup',
      type: 'button'
    }
  ];

  // Step 1: Add state to manage mobile menu visibility
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Step 2: Toggle the menu visibility when the button is clicked
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(prevState => !prevState);
  };

  return (
    <nav className="bg-white p-4  mx-auto w-full top-0 z-30 fixed" id="nav-menu">
      <div className="flex mx-auto  w-full py-2 px-8 items-center justify-between">
        <Link to="/" className="inline-block">
          <img src="/images/logo.svg" className="h-6 lg:h-10 w-auto inline-block" />
          {/* <img
            src="/images/logo-white.svg"
            className="h-10 w-auto inline-block md:hidden"
          /> */}
        </Link>

        {/* Step 3: Render mobile menu based on isMobileMenuOpen state */}
        {isMobileMenuOpen && (
          <div
            className="flex flex-col gap-1 md:hidden bg-white mt-5 wra"
            style={{
              position: 'absolute',
              background: '#92D418',
              width: '100%',
              top: '55px',
              left: '0',
              textAlign: 'center',
              padding: '20px'
            }}
          >
            {links.map(link => (
              <a
                href={link.slug}
                key={link.title}
                className="font-bold text-black px-2 inline-block   hover:underline"
              >
                {link.title}
              </a>
            ))}
          </div>
        )}

        <button className="md:hidden" onClick={toggleMobileMenu}>
          <MenuButton />
        </button>

        <div className="gap-1 hidden items-center md:inline-block">
          {links.map(link => (
            <a
              href={link.slug}
              key={link.title}
              className={`${
                link.type == 'button'
                  ? ' p-4 hover:underline border-2 border-[#2C9C1F] rounded-full bg-[#F4F4F4] text-primary-900'
                  : ' text-black px-2 inline-block hover:underline'
              } font-bold`}
            >
              {link.title}
            </a>
          ))}
        </div>
      </div>
    </nav>
  );
}

function MenuButton() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 text-[#344054] w-8"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M4 8h16M4 16h16" />
    </svg>
  );
}

import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { useSelector } from 'react-redux';

export default function MainArea({ children }) {
  const show = useSelector(state => state.uiReducer.showSearch);
  return (
    <div className="">
      <Header withSearch={show} />

      <main>
        <section className="relative  ">{children}</section>
      </main>
      <Footer />
    </div>
  );
}

import { Fragment, lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import { GuestGuard, AuthGuard } from './Guards';
import 'react-toastify/dist/ReactToastify.css';
// , toast
import { ToastContainer } from 'react-toastify';
import LoadingScreen from '../Components/LoadingScreen/LoadingScreen';
import MainArea from '../Layouts/Users/MainArea';
import AccountArea from '../Layouts/Account/AccountArea';
import { routeNames } from '../Constants/AccountConstants';
import Multiforms from '../Layouts/MultiForm/MultiForms';
import Auth from '../Layouts/Auth/Auth';
import { getSearchParam } from '../utils/utils';
import { BizmixRoutes, NewBizmixRoutes } from './BizmixRoutes';

export const renderRoutes = (routes = []) => {
  return (
    <>
      <Suspense fallback={<LoadingScreen />}>
        <Switch>
          {routes.map((route, i) => {
            const Guard = route.guard || Fragment;
            const Component = route.component;
            const Layout = route.layout || Fragment;

            return (
              <Route
                key={i}
                path={route.path}
                exact={route.exact}
                render={props => {
                  return (
                    <Guard>
                      <Layout>
                        {route.routes ? (
                          renderRoutes(route.routes)
                        ) : (
                          <Component {...props} />
                        )}
                      </Layout>
                    </Guard>
                  );
                }}
              />
            );
          })}
        </Switch>
      </Suspense>
      <ToastContainer />
    </>
  );
};

export const routes = [
  ...NewBizmixRoutes,
  //Xmass campaign routes
  {
    exact: true,
    guard: MainArea,
    path: '/christmas',
    component: lazy(() => import('../Views/Xmas/index'))
  },
  {
    exact: true,
    path: '/',
    layout: MainArea,
    component: lazy(() => import('../Views/Users/Home'))
  },
  {
    exact: true,
    path: '/google/redirect',
    layout: MainArea,
    component: lazy(() => import('../Views/Redirectpage/GoogleLoginRedirection'))
  },
  {
    exact: true,
    path: '/make-money',
    layout: MainArea,
    component: lazy(() => import('../Views/Users/StaticPages/howtomakeMoney'))
  },
  {
    exact: true,
    path: '/frequently-asked-questions',
    layout: MainArea,
    component: lazy(() => import('../Views/Users/StaticPages/faq'))
  },
  {
    exact: true,
    path: '/about-us',
    layout: MainArea,
    component: lazy(() => import('../Views/Users/StaticPages/aboutUs'))
  },
  {
    exact: true,
    path: '/terms-and-conditions',
    layout: MainArea,
    component: lazy(() => import('../Views/Users/StaticPages/terms'))
  },
  {
    exact: true,
    path: '/whats-new',
    layout: MainArea,
    component: lazy(() => import('../Views/Users/StaticPages/whats-new'))
  },
  {
    exact: true,
    path: '/list-confirm',
    layout: MainArea,
    component: lazy(() => import('../Views/Users/StaticPages/list-confirm'))
  },
  {
    exact: true,
    path: '/advertise',
    layout: MainArea,
    component: lazy(() => import('../Views/Users/StaticPages/advertise'))
  },
  {
    exact: true,
    path: '/contact-us',
    layout: MainArea,
    component: lazy(() => import('../Views/Users/StaticPages/contact'))
  },
  {
    exact: true,
    path: '/privacy-policy',
    layout: MainArea,
    component: lazy(() => import('../Views/Users/StaticPages/privacy-policy'))
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/create-listing',
    layout: Multiforms,
    component: lazy(() => import('../Views/CreateListing/index'))
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/create-business',
    layout: Multiforms,
    component: lazy(() => import('../Views/CreateBusiness/index'))
  },
  // Account Routes
  {
    exact: true,
    guard: GuestGuard,
    path: '/signup',
    layout: Auth,
    component: lazy(() => import('../Views/Auth/index'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    layout: Auth,
    component: lazy(() => import('../Views/Auth/index'))
  },
  {
    exact: true,
    path: '/verification',
    guard: AuthGuard,
    layout: Auth,
    component: lazy(() => {
      if (getSearchParam('hash') && getSearchParam('signature')) {
        return import('../Views/Auth/Verification');
      } else {
        return import('../Components/Errors/Notfound');
      }
    })
  },
  {
    exact: true,
    path: '/logout',
    layout: Auth,
    component: lazy(() => import('../Views/Auth/Logout'))
  },
  {
    exact: true,
    path: '/logout-all-devices',
    layout: Auth,
    component: lazy(() => import('../Views/Auth/LogoutAllDevices'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot-password',
    layout: Auth,
    component: lazy(() => import('../Views/Auth/forgotPassword'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/reset-password',
    layout: Auth,
    component: lazy(() => {
      if (getSearchParam('token')) {
        return import('../Views/Auth/resetPassword');
      } else {
        return import('../Components/Errors/Notfound');
      }
    })
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/promo',
    component: lazy(() => import('../Views/Users/StaticPages/promo'))
  },
  {
    exact: true,
    path: '/health-insurance-plans',
    component: lazy(() => import('../Views/RoHealth/RoHealth'))
  },
  {
    exact: true,
    path: '/club',
    component: () => {
      window.location.href = 'https://club.connectnigeria.com/';
      return <LoadingScreen />;
    }
  },
  {
    exact: true,
    path: '/survey',
    component: () => {
      window.location.href =
        'https://docs.google.com/forms/d/e/1FAIpQLSdA-VSo99soN9XoRaf986ZuDeF-Vvq0d_RJZmOIMfWw3PIM7g/viewform';
      return <LoadingScreen />;
    }
  },
  {
    guard: AuthGuard,
    path: '/trainings',
    routes: [
      {
        exact: true,
        path: '/trainings/' + routeNames.CLUBCONNECT + '/AddTrainings',
        component: lazy(() => import('../Views/Account/ClubConnect/Training/AddTraining'))
      }
    ]
  },
  {
    guard: AuthGuard,
    path: '/account',
    layout: AccountArea,
    routes: [
      {
        exact: true,
        path: '/account',
        component: lazy(() => import('../Views/Account/GetStarted'))
      },
      {
        exact: true,
        path: '/account/' + routeNames.GET_STARTED,
        component: lazy(() => import('../Views/Account/GetStarted'))
      },
      {
        exact: true,
        path: '/account/' + routeNames.DASHBOARD,
        component: lazy(() => import('../Views/Account/Dashboard'))
      },
      {
        exact: true,
        path: '/account/profile',
        component: lazy(() => import('../Views/Account/Profile/Profile'))
      },
      // Account business pages
      {
        exact: true,
        path: '/account/' + routeNames.BUSINESSES,
        component: lazy(() => import('../Views/Account/Business/Businesses'))
      },
      {
        path: '/account/' + routeNames.BUSINESSES + '/create',
        component: lazy(() => (window.location.href = '/create-business'))
      },
      {
        exact: true,
        path: '/account/' + routeNames.BUSINESSES + '/:id',
        component: lazy(() => import('../Views/Account/Business/BusinessDetails'))
      },
      {
        exact: true,
        path: '/account/' + routeNames.BUSINESSES + '/:id/promote',
        component: lazy(() => import('../Views/Account/reusables/Promotion'))
      },
      {
        exact: true,
        path: '/account/' + routeNames.BUSINESSES + '/:id/update',
        component: lazy(() =>
          import('../Views/Account/Business/Update/BusinessUpdatePage')
        )
      },
      // Account listing pages
      {
        exact: true,
        path: '/account/' + routeNames.LISTINGS,
        component: lazy(() => import('../Views/Account/Listing/Listings'))
      },
      {
        exact: true,
        path: '/account/' + routeNames.LISTINGS + '/create',
        component: lazy(() => (window.location.href = '/create-listing'))
      },
      {
        exact: true,
        path: '/account/' + routeNames.LISTINGS + '/:encodedId',
        component: lazy(() => import('../Views/Account/Listing/ListingDetails'))
      },
      {
        exact: true,
        path: '/account/' + routeNames.LISTINGS + '/:encodedId/promote',
        component: lazy(() => import('../Views/Account/reusables/Promotion'))
      },
      {
        exact: true,
        path: '/account/' + routeNames.LISTINGS + '/:encodedId/update',
        component: lazy(() => import('../Views/Account/Listing/Update/ListingUpdatePage'))
      },
      // Account Adverts pages
      {
        exact: true,
        path: '/account/' + routeNames.ADVERTS,
        component: lazy(() => import('../Views/Account/Adverts/AdvertsIndexPage'))
      },
      {
        exact: true,
        path: '/account/' + routeNames.ADVERTS + '/list',
        component: lazy(() => import('../Views/Account/Adverts/AdvertsListPage'))
      },
      // Account Connections pages
      {
        exact: true,
        path: '/account/' + routeNames.CONNECTIONS,
        component: lazy(() => import('../Views/Account/connections/ConnectionsIndexPage'))
      },
      // Account Invoices pages
      {
        exact: true,
        path: '/account/' + routeNames.INVOICES,
        component: lazy(() => import('../Views/Account/Invoices/InvoicesIndexPage'))
      },
      // Account > Quote Requests
      {
        exact: true,
        path: '/account/' + routeNames.QUOTE_REQUEST,
        component: lazy(() => import('../Views/Account/QuoteRequests/QuoteRequestsPage'))
      },
      // Saved
      {
        exact: true,
        path: '/account/' + routeNames.SAVED,
        component: lazy(() => import('../Views/Account/Saved/Saved'))
      },
      // Clubconnect
      {
        exact: true,
        path: '/account/' + routeNames.CLUBCONNECT,
        component: lazy(() => import('../Views/Account/ClubConnect/ClubConnect'))
      },
      {
        exact: true,
        path: '/account/' + routeNames.CLUBCONNECT + '/merchants',
        component: lazy(() =>
          import('../Views/Account/ClubConnect/Merchants/MerchantList')
        )
      },
      {
        exact: true,
        path: '/account/' + routeNames.CLUBCONNECT + '/merchants/:id',
        component: lazy(() => import('../Views/Account/ClubConnect/Merchants/Merchant'))
      },
      {
        exact: true,
        path: '/account/' + routeNames.CLUBCONNECT + '/merchants-feature',
        component: lazy(() =>
          import('../Views/Account/ClubConnect/Merchants/Feature/Business')
        )
      },
      {
        exact: true,
        path: '/account/' + routeNames.CLUBCONNECT + '/merchants-feature/:businessId',
        component: lazy(() =>
          import('../Views/Account/ClubConnect/Merchants/Feature/Listing')
        )
      },
      {
        exact: true,
        path: '/account/' + routeNames.CLUBCONNECT + '/membership',
        component: lazy(() => import('../Views/Account/ClubConnect/Membership'))
      },
      {
        exact: true,
        path: '/account/' + routeNames.CLUBCONNECT + '/bizmix',
        component: lazy(() => import('../Views/Account/ClubConnect/Bizmix/Bizmix'))
      },
      {
        exact: true,
        path: '/account/' + routeNames.CLUBCONNECT + '/bizmix/register',
        component: lazy(() => import('../Views/Account/ClubConnect/Bizmix/Register'))
      },
      {
        exact: true,
        path: '/account/' + routeNames.CLUBCONNECT + '/cac',
        component: lazy(() => import('../Views/Account/ClubConnect/Cac/Cac'))
      },
      {
        exact: true,
        path: '/account/' + routeNames.CLUBCONNECT + '/cac/register',
        component: lazy(() => import('../Views/Account/ClubConnect/Cac/Register'))
      },
      {
        exact: true,
        path: '/account/' + routeNames.CLUBCONNECT + '/trainings',
        component: lazy(() => import('../Views/Account/ClubConnect/Training/Training'))
      },
      {
        exact: true,
        path: '/account/' + routeNames.CLUBCONNECT + '/trainings/:id',
        component: lazy(() =>
          import('../Views/Account/ClubConnect/Training/TrainingDetail')
        )
      },
      {
        exact: true,
        path: '/account/' + routeNames.CLUBCONNECT + '/articles',
        component: lazy(() => import('../Views/Account/ClubConnect/Articles/Articles'))
      },
      {
        exact: true,
        path: '/account/' + routeNames.CLUBCONNECT + '/articles/create',
        component: lazy(() =>
          import('../Views/Account/ClubConnect/Articles/CreateArticle')
        )
      },
      {
        exact: true,
        path: '/account/' + routeNames.CLUBCONNECT + '/articles/:id',
        component: lazy(() => import('../Views/Account/ClubConnect/Articles/EditArticle'))
      },
      {
        exact: true,
        path: '/account/' + routeNames.CLUBCONNECT + '/featured-listing',
        component: lazy(() =>
          import('../Views/Account/ClubConnect/FeaturedListings/FeaturedListings')
        )
      },
      {
        exact: true,
        path: '/account/' + routeNames.CLUBCONNECT + '/featured-listing/create',
        component: lazy(() =>
          import('../Views/Account/ClubConnect/FeaturedListings/Create')
        )
      },
      {
        exact: true,
        path: '/account/' + routeNames.CLUBCONNECT + '/adverts',
        component: lazy(() => import('../Views/Account/ClubConnect/Adverts/Adverts'))
      },

      // End of Account routes
      {
        path: '*',
        routes: [
          {
            component: lazy(() => import('../Views/Account/Errors/NotFound'))
          }
        ]
      }
    ]
  },
  {
    path: '*',
    routes: [
      {
        component: lazy(() => import('../Components/Errors/Notfound'))
      }
    ]
  }
];
